import React from 'react'
import { Link } from 'react-router-dom'
import ApplyForm from '../component/ApplyForm'
import useLocales from '../locales/useLocales';

export default function Courses() {
  const { translate } = useLocales();
  const [openApplyForm,setOpenApplyForm]=React.useState(false)
  return (
    <>
{openApplyForm && <ApplyForm onClose={()=>{setOpenApplyForm(false)}}/>}
    <section className="courses_section section_space_lg" id="explore-courses">
    <div className="container">
      <div className="section_heading">
        <div className="row align-items-center">
          <div className="col col-md-6">
            <h2 className="heading_text mb-0">{translate('Our Courses')}</h2>
          </div>
          <div className="col col-md-6 d-none d-lg-flex justify-content-end">
            <div className="btn_wrap p-0">
              <button className="btn btn_primary" onClick={()=>{setOpenApplyForm(true)}}>
                <span>
                  <small>{translate('Apply Now')}</small> <small>{translate('Apply Now')}</small>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col col-lg-4">
          <article className="card">
            <img
              className="card__background"
              src="assets/images/course/english-ukUs.webp"
              alt="english-in-uk"
              width="1920"
              height="2193"
            />
            <div className="card__content | flow">
              <div className="card__content--container | flow">
                <h2 className="card__title">{translate('English')}</h2>
                <p className="card__description mb-0 mt-3">
                {translate('We offer our students various English Language courses and programs that suits their individual needs')}
                </p>
              </div>
              <Link
                className="btn btn_primary btn-sm card__button"
                to="/english-in-uk"
              >
                <span>
                  <small>{translate('Read More')}</small>
                  <small>{translate('Read More')}</small>
                </span>
              </Link>
            </div>
          </article>
        </div>
        <div className="col col-lg-4">
          <article className="card">
            <img
              className="card__background"
              src="assets/images/course/Foundation.webp"
              alt="UKUD – Online Learning Platform"
              width="1920"
              height="2193"
            />
            <div className="card__content | flow">
              <div className="card__content--container | flow">
                <h2 className="card__title">{translate('Foundation')}</h2>
                <p className="card__description mb-0 mt-3">
                {translate('Students who have completed their High School could apply to join a suitable Foundation program...')}
                </p>
              </div>
              <Link
                className="btn btn_primary btn-sm card__button"
                to="/foundation-in-uk"
              >
                <span>
                <small>{translate('Read More')}</small>
                <small>{translate('Read More')}</small>
                </span>
              </Link>
            </div>
          </article>
        </div>
        <div className="col col-lg-4">
          <article className="card">
            <img
              className="card__background"
              src="assets/images/course/bachelor.webp"
              alt="UKUD – Online Learning Platform"
              width="1920"
              height="2193"
            />
            <div className="card__content | flow">
              <div className="card__content--container | flow">
                <h2 className="card__title">{translate("Bachelor's Degree")}</h2>
                <p className="card__description mb-0 mt-3">
                {translate("A bachelor's degree is a significant milestone in one's educational journey, typically spanning three to four years of undergraduate study.")}
                </p>
              </div>
              <Link
                className="btn btn_primary btn-sm card__button"
                to="/undergraduate-in-uk"
              >
                <span>
                <small>{translate('Read More')}</small>
                <small>{translate('Read More')}</small>
                </span>
              </Link>
            </div>
          </article>
        </div>
        <div className="col col-lg-4">
          <article className="card">
            <img
              className="card__background"
              src="assets/images/course/MASTERS-DEGREE.webp"
              alt="UKUD – Online Learning Platform"
              width="1920"
              height="2193"
            />
            <div className="card__content | flow">
              <div className="card__content--container | flow">
                <h2 className="card__title">{translate('Masters')}</h2>
                <p className="card__description mb-0 mt-3">
                {translate("We offer a wide range of Master's and Pre-Master programs designed to elevate your education and career to the next level.")}
                </p>
              </div>
              <Link
                className="btn btn_primary btn-sm card__button"
                to="/master-in-uk"
              >
                <span>
                <small>{translate('Read More')}</small>
                <small>{translate('Read More')}</small>
                </span>
              </Link>
            </div>
          </article>
        </div>
        <div className="col col-lg-4">
          <article className="card">
            <img
              className="card__background"
              src="assets/images/course/phd.webp"
              alt="UKUD – Online Learning Platform"
              width="1920"
              height="2193"
            />
            <div className="card__content | flow">
              <div className="card__content--container | flow">
                <h2 className="card__title">{translate('PhD Research')}</h2>
                <p className="card__description mb-0 mt-3">
                {translate('Doctoral programs are designed to empower you to contribute to the world of academia and make meaningful advancements in your chosen field.')}
                </p>
              </div>
              <Link className="btn btn_primary btn-sm card__button" to="/phd">
                <span>
                <small>{translate('Read More')}</small>
                <small>{translate('Read More')}</small>
                </span>
              </Link>
            </div>
          </article>
        </div>
        <div className="col col-lg-4">
          <article className="card">
            <img
              className="card__background"
              src="assets/images/course/clinical-pharmacy.webp"
              alt="UKUD – Online Learning Platform"
              width="1920"
              height="2193"
            />
            <div className="card__content | flow">
              <div className="card__content--container | flow">
                <h2 className="card__title">{translate('Clinical Pharmacy')}</h2>
                <p className="card__description mb-0 mt-3">
                {translate('This program is very unique as it gives students holding a Degree in Pharmacy a unique opportunity to study towards a Masters of Clinical Pharmacy')}
                </p>
              </div>
              <Link
                className="btn btn_primary btn-sm card__button"
                to="/clinical-pharmacy"
              >
                <span>
                <small>{translate('Read More')}</small>
                <small>{translate('Read More')}</small>
                </span>
              </Link>
            </div>
          </article>
        </div>
      </div>
      <div className="btn_wrap d-block d-lg-none pb-0 text-center">
        <button className="btn btn_primary" onClick={()=>{setOpenApplyForm(true)}}>
          <span>
            <small>Apply Now</small> <small>Apply Now</small>
          </span>
        </button>
      </div>
    </div>
  </section>
  </>
  )
}
