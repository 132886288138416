import { useLocation, Outlet } from "react-router-dom";
// @mui
import { Stack } from "@mui/material";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";


// components
// import Logo from '../../components/Logo';
//

// ----------------------------------------------------------------------

export default function MainLayout({ children }) {
  const { pathname } = useLocation();


  return (
    <Stack sx={{ minHeight: 1 }}>
      <div className="page_wrapper">
        <div className="backtotop mdp-float">
          <a href="https://api.whatsapp.com/send?phone=447518177067" target="_blank" className="scroll mdp-float-btn" >
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>


        <Navbar />
        <main className="page_content">
          {children}
          <Outlet />
        </main>
        <Footer />
      </div>
    </Stack>
  );
}
