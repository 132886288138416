// import './App.css'; 
// import RtlLayout from './component/RtlLayout';
import AnimatedCursor from 'react-animated-cursor';
import ScrollToTop from './component/ScrollToTop';
import Router from './routes';
import { useEffect } from 'react';

function App() {
  return (
    <>
    <AnimatedCursor
  innerSize={8}
  outerSize={20}
  innerScale={1}
  outerScale={1}
  outerAlpha={0}
  color='123, 00, 111'
  showSystemCursor

  // hasBlendMode={true}
  clickables={[
    // 'a',
    'input[type="text"]',
    'input[type="email"]',
    'input[type="number"]',
    'input[type="submit"]',
    'input[type="image"]',
    'label[for]',
    'select',
    'textarea',
    'button',
    '.link', 
    {
      target: 'a',
      options: {
       cursor:"pointer"
      }
    }
  ]}
  innerStyle={{
    backgroundColor: 'black'
  }}
  outerStyle={{
    border: '3px solid black'
  }}
/>
<ScrollToTop/>
    <Router />
    </>
  
  );
}

export default App;
