import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import useLocales from "../locales/useLocales";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarCustom({open,onClose,msg,type}) {

  const { translate} = useLocales();
  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>

      <Snackbar open={open} autoHideDuration={6000} onClose={onClose} anchorOrigin={{ horizontal:"center",vertical:"top"}}>
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          {translate(msg) || "Something went wrong"}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
