import React from "react";
// import NavbarHorizontal from "../navbar/NavbarHorizontal";
import { Link } from "react-router-dom";
import Testimonial from "./Testimonial";
import Courses from "./Courses";
import MainLayout from "../main/MainLayout";

import Vedio from "../component/Vedio";
import ApplyForm from "../component/ApplyForm";
import CountUp from "react-countup";
import ReactVisibilitySensor from "react-visibility-sensor";
import useLocales from "../locales/useLocales";
import { LazyLoadComponent } from "react-lazy-load-image-component";

export default function Home() {
  const [open, setOpen] = React.useState(false);
  const [openApplyForm, setOpenApplyForm] = React.useState(false);
  const { translate } = useLocales();
  return (
    <>
      {openApplyForm && (
        <ApplyForm
          onClose={() => {
            setOpenApplyForm(false);
          }}
        />
      )}
      {open && (
        <Vedio
          onClose={() => {
            setOpen(false);
          }}
          src="https://www.youtube.com/embed/jbIzH3OPeRA?si=OAMPSxSb9i8Pc6iz"
        />
      )}
      <MainLayout>
        <section className="hero_banner style_1">
          <div className="container">
            <div className="content_wrap">
              <div className="row">
                <div className="col col-lg-7">
                  <h1 className="banner_small_title">{translate('Learning Excellence')}</h1>
                  <h2 className="banner_big_title">
                  {translate('Facilitating Universal Education Since 2007')}
                  </h2>
                  <p className="banner_description">
                  {translate(`heroSection paragraph`)}
                  </p>
                  <ul className="banner_btns_group unordered_list">
                    <li>
                      <Link className="btn btn_dark" to="/all-courses">
                        <span>
                          <small>{translate('Explore Courses')}</small>
                          <small>{translate('Explore Courses')}</small>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <button
                        className="video_play_btn text-white popup_video"
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        <span className="icon" data-magnetic>
                          <i className="fas fa-play"></i>
                        </span>
                        <span className="text">{translate('Watch video')}</span>
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="col col-lg-5">
                  <div className="banner_image_1 decoration_wrap">
                    <div className="image_wrap">
                      <img 
                        src="assets/images/banner/hero_banner_img_1.webp"
                        alt="UKUD – Online Learning Platform"
                        fetchpriority="high"
                      />
                    </div>
                    <div className="satisfied_student">
                      <h3 className="wrap_title">
                      {translate('2000+ a year Satisfied Student')}
                      </h3>
                      <ul className="students_thumbnail unordered_list_center">
                        <li>
                          <span>
                            <img loading="lazy"
                              src="assets/images/meta/student_thumbnail_1.webp"
                              alt="UKUD – Online Learning Platform"
                            />
                          </span>
                        </li>
                        <li>
                          <span>
                            <img loading="lazy"
                              src="assets/images/meta/student_thumbnail_2.webp"
                              alt="UKUD – Online Learning Platform"
                            />
                          </span>
                        </li>
                        <li>
                          <span>
                            <img loading="lazy"
                              src="assets/images/meta/student_thumbnail_3.webp"
                              alt="UKUD – Online Learning Platform"
                            />
                          </span>
                        </li>
                        <li>
                          <span>
                            <img loading="lazy"
                              src="assets/images/meta/student_thumbnail_4.webp"
                              alt="UKUD – Online Learning Platform"
                            />
                          </span>
                        </li>
                        <li>
                          <span>
                            <img loading="lazy"
                              src="assets/images/meta/student_thumbnail_5.webp"
                              alt="UKUD – Online Learning Platform"
                            />
                          </span>
                        </li>
                      </ul>
                    </div>
                    {/* <div
                      className="deco_item shape_img_1"
                      data-parallax='{"y" : -130, "smoothness": 6}'
                    >
                      <img loading="lazy"
                        src="assets/images/shape/shape_img_1.png"
                        alt="UKUD – Online Learning Platform"
                      />
                    </div>
                    <div
                      className="deco_item shape_img_2"
                      data-parallax='{"y" : 160, "smoothness": 6}'
                    >
                      <img loading="lazy"
                        src="assets/images/shape/shape_img_2.png"
                        alt="UKUD – Online Learning Platform"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <LazyLoadComponent key={'about-us'} visibleByDefault={false}   >
        <section className="expect_from_course section_space_lg">
          <div className="container">
            <div className="row">
              <div className="col col-lg-6">
                <div className="section_heading">
                  <h2 className="heading_text">
                  {translate('UKUD Turning Educational Dreams into Reality')}
                  </h2>
                  <p className="heading_description mb-0">
                  {translate('aboutUsParagraph')}
                  </p>
                </div>
                <div className="image_widget">
                  <img loading="lazy"
                    src="assets/images/about/about_image_1.webp"
                    alt="UKUD – Online Learning Platform"
                  />
                </div>
              </div>
              <div className="col col-lg-6">
                <div className="btn_wrap pt-0 d-none d-lg-flex justify-content-end">
                  <button
                    className="btn btn_primary"
                    onClick={() => {
                      setOpenApplyForm(true);
                    }}
                  >
                    <span>
                      <small>{translate('Apply Now')}</small>
                      <small>{translate('Apply Now')}</small>
                    </span>
                  </button>
                </div>
                <div className="row">
                  <div className="col col-md-6">
                    <div className="service_item" data-magnetic>
                      <div className="item_icon">
                        <img loading="lazy"
                          src="assets/images/service/icon_academic_cap.svg"
                          alt="UKUD – Online Learning Platform"
                        />
                      </div>
                      <div className="item_content">
                        <h3 className="item_title">{translate('Study choices')}</h3>
                        <p className="mb-0">
                        {translate('Studying abroad is not an easy choice to make. We can help you find a suitable course')}
                        </p>
                      </div>
                      <a href="#study-choices" className="btn_unfill readMore">
                      {translate('Read More')}&nbsp;&nbsp;
                        <i
                          className="fas fa-long-arrow-right"
                          style={{ marginTop: "4px" }}
                        ></i>
                      </a>
                    </div>
                  </div>
                  <div className="col col-md-6">
                    <div className="service_item" data-magnetic>
                      <div className="item_icon">
                        <img loading="lazy"
                          src="assets/images/service/icon_physics.svg"
                          alt="UKUD – Online Learning Platform"
                        />
                      </div>
                      <div className="item_content">
                        <h3 className="item_title">{translate('Grades matching')}</h3>
                        <p className="mb-0">
                        {translate('Match your qualifications to degree course possibilities:')}
                        </p>
                      </div>
                      <a href="#grad-matching" className="btn_unfill readMore">
                      {translate('Read More')}&nbsp;&nbsp;
                        <i
                          className="fas fa-long-arrow-right"
                          style={{ marginTop: "4px" }}
                        ></i>
                      </a>
                    </div>
                  </div>
                  <div className="col col-md-6">
                    <div className="service_item" data-magnetic>
                      <div className="item_icon">
                        <img loading="lazy"
                          src="assets/images/service/icon_communication.svg"
                          alt="UKUD – Online Learning Platform"
                        />
                      </div>
                      <div className="item_content">
                        <h3 className="item_title">{translate('Budget Assessment')}</h3>
                        <p className="mb-0">
                        {translate('for living at university: get a rough idea of what your living costs at university will be with our student budget advise service')}
                        </p>
                      </div>
                      <a
                        href="#budget-assessment"
                        className="btn_unfill readMore"
                      >
                        {translate('Read More')}&nbsp;&nbsp;
                        <i
                          className="fas fa-long-arrow-right"
                          style={{ marginTop: "4px" }}
                        ></i>
                      </a>
                    </div>
                  </div>
                  <div className="col col-md-6">
                    <div className="service_item" data-magnetic>
                      <div className="item_icon">
                        <img loading="lazy"
                          src="assets/images/service/icon_diploma.svg"
                          alt="UKUD – Online Learning Platform"
                        />
                      </div>
                      <div className="item_content">
                        <h3 className="item_title">{translate('A certificate')}</h3>
                        <p className="mb-0">
                        {translate('In recognition of outstanding academic achievement and dedication to lifelong learning.')}
                        </p>
                      </div>
                      <Link
                        href="#explore-courses"
                        className="btn_unfill readMore"
                        to="/about-us"
                      >
                        {translate('Read More')}&nbsp;&nbsp;
                        <i
                          className="fas fa-long-arrow-right"
                          style={{ marginTop: "4px" }}
                        ></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="btn_wrap pb-0 d-block d-lg-none text-center">
                  <a className="btn border_dark" href="course.html">
                    <span>
                      <small>{translate('Explore Courses')}</small>
                      <small>{translate('Explore Courses')}</small>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        </LazyLoadComponent>

        <LazyLoadComponent key={'counter'} visibleByDefault={false}  >
        <section className="counter_section section_space_md" style={{background:'#20313d'}}>
          <div className="container">
            <div className="row">
              <div className="col col-lg-3 col-md-6">
                <div className="counter_item">
                  <h3 className="counter_value">
                    <span className="counter_value_text">
                      <CountUp end={2000} duration={30}>
                        {({ countUpRef, start }) => (
                          <ReactVisibilitySensor onChange={start}>
                            <span ref={countUpRef} />
                          </ReactVisibilitySensor>
                        )}
                      </CountUp>
                    </span>
                    <span>+</span>
                  </h3>
                  <p className="mb-0 text-white">{translate('International Students Every Year')}</p>
                </div>
              </div>
              <div className="col col-lg-3 col-md-6">
                <div className="counter_item">
                  <h3 className="counter_value">
                    <span className="counter_value_text">
                      <CountUp end={600} duration={20}>
                        {({ countUpRef, start }) => (
                          <ReactVisibilitySensor onChange={start}>
                            <span ref={countUpRef} />
                          </ReactVisibilitySensor>
                        )}
                      </CountUp>
                    </span>
                    <span>+</span>
                  </h3>
                  <p className="mb-0 text-white">{translate('Courses')}</p>
                </div>
              </div>
              <div className="col col-lg-3 col-md-6">
                <div className="counter_item">
                  <h3 className="counter_value">
                    <span className="counter_value_text">
                      <CountUp end={200} duration={20}>
                        {({ countUpRef, start }) => (
                          <ReactVisibilitySensor onChange={start}>
                            <span ref={countUpRef} />
                          </ReactVisibilitySensor>
                        )}
                      </CountUp>
                     
                    </span>
                    <span>+</span>
                  </h3>
                  <p className="mb-0 text-white">{translate('Top University Partner')}</p>
                </div>
              </div>
              <div className="col col-lg-3 col-md-6">
                <div className="counter_item">
                  <h3 className="counter_value">
                    <span className="counter_value_text"> <CountUp end={4} duration={4}>
                        {({ countUpRef, start }) => (
                          <ReactVisibilitySensor onChange={start}>
                            <span ref={countUpRef} />
                          </ReactVisibilitySensor>
                        )}
                      </CountUp></span>
                    <span>+</span>
                  </h3>
                  <p className="mb-0 text-white">{translate('Countries')}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        </LazyLoadComponent>

        <LazyLoadComponent key={'course'} visibleByDefault={false}  >
        <Courses />
        </LazyLoadComponent>
        <LazyLoadComponent key={'university'} visibleByDefault={false}  >
        <section className="advertisement_section bg_blue" id="study-choices">
          <div className="container">
            <div className="row align-items-center">
              <div className="col col-lg-6">
                <div className="section_heading mb-lg-0">
                  <h2 className="heading_text test_mustered">
                  {translate('We can help you with your Study choices...')}
                  </h2>
                  <p className="heading_description mb-3 text-white">
                  {translate('ukudInfo')}
                  </p>
                  <p className="heading_description mb-0 text-white">
                  {translate('ukudInfoSecondPara')}
                  </p>
                  <div className="btn_wrap pb-0">
                    <Link className="btn btn_dark" to="/contact-us">
                      <span>
                        <small>{translate('Contact Us')}</small>
                        <small>{translate('Contact Us')}</small>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col col-lg-6">
                <div className="row images_group decoration_wrap">
                  <div className="col col-md-6 col-sm-6 col-6">
                    <div className="image_wrap">
                      <img loading="lazy"
                        src="assets/images/advertisement/durham_uni.webp"
                        alt="UKUD – Online Learning Platform"
                      />
                    </div>
                    <div className="image_wrap">
                      <img loading="lazy"
                        src="assets/images/advertisement/northampton.webp"
                        alt="UKUD – Online Learning Platform"
                      />
                    </div>
                  </div>
                  <div className="col col-md-6 col-sm-6 col-6">
                    <div className="image_wrap">
                      <img loading="lazy"
                        src="assets/images/advertisement/shefield.webp"
                        alt="UKUD – Online Learning Platform"
                      />
                    </div>
                    <div className="image_wrap">
                      <img loading="lazy"
                        src="assets/images/advertisement/edinburgh.webp"
                        alt="UKUD – Online Learning Platform"
                      />
                    </div>
                  </div>
                  <div
                    className="deco_item shape_img_1"
                    data-parallax='{"y" : -80, "smoothness": 6}'
                  >
                    <img loading="lazy"
                      src="assets/images/shape/shape_img_3.png"
                      alt="UKUD – Online Learning Platform"
                    />
                  </div>
                  <div
                    className="deco_item shape_img_2"
                    data-parallax='{"y" : 80, "smoothness": 6}'
                  >
                    <img loading="lazy"
                      src="assets/images/shape/shape_img_3.png"
                      alt="UKUD – Online Learning Platform"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </LazyLoadComponent>
        <LazyLoadComponent key={'course-info'} visibleByDefault={false}  >
        <section
          className="courses_info_section section_space_lg"
          id="grad-matching"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col col-lg-6">
                <div className="image_widget">
                  <img loading="lazy"
                    src="assets/images/about/about_image_2.webp"
                    alt="UKUD – Online Learning Platform"
                  />
                </div>
              </div>
              <div className="col col-lg-6">
                <div className="content_wrap ps-lg-3">
                  <div className="section_heading mb-2">
                    <h2 className="heading_text">
                    {translate('We can help you with your Grades matching')}
                    </h2>
                    <p className="heading_description mb-0">
                      {translate('GradeMatchingParagraph')}
                    </p>
                  </div>

                  <div className="btn_wrap pb-0">
                    <Link className="btn btn_dark" to="/contact-us">
                      <span>
                        <small>{translate('Contact Us')}</small>
                        <small>{translate('Contact Us')}</small>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </LazyLoadComponent>
        <LazyLoadComponent key={'budget'} visibleByDefault={false}  >
        <section className="page_banner" id="budget-assessment">
          <div className="container">
            <div className="content_wrapper">
              <div className="row align-items-center">
                <div className="col col-lg-7">
                  <div className="section_heading mb-2">
                    <h2 className="heading_text test_mustered">
                    {translate('We can help you with your')} <br />
                    {translate('Budget assessment ….')}
                    </h2>
                  </div>
                  <p className="page_description text-white">
                    {translate('budgetAssessmentParagraph')}
                  </p>

                  <div className="form_item mb-0">
                    <button
                      type="submit"
                      className="btn btn_primary"
                      onClick={() => setOpenApplyForm(true)}
                    >
                      <span>
                        <small>{translate('Apply Now')}</small>
                        <small>{translate('Apply Now')}</small>
                      </span>
                    </button>
                  </div>
                </div>
                <div className="col col-lg-5">
                  <div className="image_widget ">
                    <img loading="lazy"
                      src="assets/images/course/english-ukUs.webp"
                      alt="ukud-education-consultancy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </LazyLoadComponent>
        <LazyLoadComponent key={'Testimonial'} visibleByDefault={false}  >
        <Testimonial />
        </LazyLoadComponent>
      </MainLayout>
    </>
  );
}
