import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { IconButton } from "@mui/material";

export default function Vedio({ onClose, src }) {
  return (
    <div style={{ position: "relative" }}>
      <Dialog
        open={true}
        onClose={(event, reason) => {
          if (reason && reason === "backdropClick" && "escapeKeyDown") {
            return;
          }
          onClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        sx={{
          overflow: "visible",
          ".MuiPaper-root": {
            background: "transparent !important",
            padding: "0px",
            margin: "0px",
            overflow: "visible",
          },
          ".MuiDialogContent-root": { padding: 0 },
          ".MuiBackdrop-root": { background: "rgb(0 0 0 / 89%)" },
        }}
        
      >
        <IconButton
          size="small"
          sx={{ position: "absolute", color: "white", right: 0, top: "-29px" }}
          onClick={onClose}
        >
          <i className="fas fa-times"></i>
        </IconButton>
        <DialogContent sx={{ background: "transparent" }}>
        <iframe width="700" height="415" src={src} title="UKUD" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </DialogContent>
      </Dialog>
    </div>
  );
}
