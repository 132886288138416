import React from "react";
import MainLayout from "../main/MainLayout";
import useLocales from "../locales/useLocales";

export default function TermsAndCondition() {
  const { translate} = useLocales();
  return (
    <MainLayout>
      <section className="details_section course_details_section section_space_md ">
        <div className="container">
          <div className="section_heading text-center">
            <h1 className="page_title">{translate('Disclaimer')}</h1>
            <p style={{ fontWeight: "600" }}>
              {translate('Copyright, Disclaimer and Terms and Conditions')}
            </p>
          </div>

        
          <p className="mb-3">
            {translate('termsAndConditionPara')}
          </p>
          <h5 className="mb-2">1. {translate('Customer care')}</h5>
          <ul className="info_list unordered_list_block mb-5">
            <li>
              <i className="fas fa-square"></i>
              <span>
                {translate('Customer care term1')}
              </span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>{translate('Customer care term2')}</span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>
              {translate('Customer care term3')}
              </span>
            </li>
          </ul>

          <h5 className="mb-2">2. {translate('Availability')}</h5>
          <p className="mb-5">
           {translate('Ability term1')}
          </p>
          <h5 className="mb-2">3. {translate('Liability for content')}</h5>
          <p className="mb-5">
            {translate('liability term1')}
          </p>
          <h5 className="mb-2">4. {translate('Liability for third party content')}</h5>
          <p className="mb-5">
           {translate('liability third party term1')}
          </p>
          <h5 className="mb-2">5. {translate('Copy Right')}</h5>
          <ul className="info_list unordered_list_block mb-5">
            <li>
              <i className="fas fa-square"></i>
              <span>
                {translate('Copy Right term 1')}
              </span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>
              {translate('Copy Right term 2')}
              </span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>
              {translate('Copy Right term 3')}
              </span>
            </li>
          </ul>
          <h5 className="mb-2">6. {translate('Exclusion of liability')}</h5>
          <ul className="info_list unordered_list_block mb-5">
            <li>
              <i className="fas fa-square"></i>
              <span>
                {translate('Exclusion term1')}
              </span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>
               {translate('Exclusion term2')}
              </span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>
              {translate('Exclusion term3')}
              </span>
            </li>
          </ul>
          <h5 className="mb-2">7. {translate('Variation')}</h5>
          <ul className="info_list unordered_list_block mb-5">
            <li>
              <i className="fas fa-square"></i>
              <span>
                {translate('Variation term1')}
              </span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>
              {translate('Variation term2')}
              </span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>
              {translate('Variation term3')}
              </span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>
              {translate('Variation term4')}
              </span>
            </li>
          </ul>

          <h5 className="mb-2">8. {translate('Termination')}</h5>
          <p className="mb-5">
           {translate('termination terms')}
          </p>

          <h5 className="mb-2">9. {translate('General')}</h5>
          <ul className="info_list unordered_list_block mb-5">
            <li>
              <i className="fas fa-square"></i>
              <span>
               {translate('General term1')}
              </span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>
              {translate('genral term2')}
              </span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>
              {translate('Genral term3')}
              </span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>
              {translate('Genral term4')}
              </span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>
              {translate('Genral term5')}
              </span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>
              {translate('Genral Term6')}
              </span>
            </li>
          </ul>

          <h5 className="mb-2">10. {translate('Data Protection')}</h5>
          <p className="mb-5">
          {translate('Data Protection term')}
          </p>

          <h5 className="mb-2">11. {translate('Definitions')}</h5>
          <p className="mb-5">
          {translate('Definitions term')}
          </p>
        </div>
      </section>
    </MainLayout>
  );
}
