import React from "react";
import { Link } from "react-router-dom";
import useLocales from "../locales/useLocales";

export default function Footer() {
  const { translate, currentLang } = useLocales();
  return (
    <footer className="site_footer">
      <div className="footer_widget_area">
        <div className="container">
          <div className="row">
            <div className="col col-lg-3 col-md-3 col-sm-6">
              <div className="footer_widget">
                <div className="site_logo">
                  <Link to="/" className="site_link">
                    <img
                      src="assets/images/logo/ukud-logo-white.svg"
                      alt="UKUD - Online Learning Platform"
                    />
                  </Link>
                </div>
                <p>
                  UKUD International Education Consultancy <br />
                  Hamilton House. 9 Hucknall Road. Nottingham NG5 1AE
                </p>
                <ul className="ps-0 contactList">
                  <li>
                    <Link to="tel:+441159648211" target="_blank">
                      <span>{translate("Call")}:</span>
                      <span className="item_text" dir="ltr">
                        +441159648211
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://api.whatsapp.com/send?phone=447518177067"
                      target="_blank"
                    >
                      <span>{translate("Whatsapp")}:</span>
                      <span className="item_text" dir="ltr">
                        +447518177067
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="mailto:admin@ukud.co.uk" target="_blank">
                      <span>{translate("E-Mail")}:</span>
                      <span className="item_text">admin@ukud.co.uk</span>
                    </Link>
                  </li>
                </ul>
                <ul className="social_links unordered_list">
                  <li>
                    <Link
                      to="https://www.facebook.com/people/Ukud-Educations/pfbid02EowRkRgeL3aRdKww1sZdtMaC7F6uKi7NSV2DPq7fRa4Zzi2Rip5n8egNbnmNp3etl/"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/in/dr-nagwa-radwan-b2373140?originalSubdomain=uk"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://twitter.com/ukud_ltd" target="_blank">
                      <i className="fab fa-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.youtube.com/channel/UCC2yD7bNOkI1DBT_XItMulw"
                      target="_blank"
                    >
                      <i className="fab fa-youtube"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/ukud_ltd/"
                      target="_blank"
                    >
                      <i className="fa fa-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-3 col-md-3 col-sm-6">
              <div className="footer_widget">
                <h3 className="footer_widget_title">
                  {translate("Navigation")}
                </h3>
                <ul className="page_list unordered_list_block">
                  <li>
                    <Link to="/">
                      <span className="item_icon">
                        <i className="fas fa-caret-right"></i>
                      </span>
                      <span className="item_text">
                        {translate("Home")}{" "}
                        {currentLang.value === "ar" &&
                          "مكتب قبولات جامعيه بريطانيا"}
                      </span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/english-in-uk">
                      <span className="item_icon">
                        <i className="fas fa-caret-right"></i>
                      </span>
                      <span className="item_text">
                        {translate("English")}{" "}
                        {currentLang.value === "ar" &&
                          "معاهد اللغه الانجليزيه في بريطانيا"}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/foundation-in-uk">
                      <span className="item_icon">
                        <i className="fas fa-caret-right"></i>
                      </span>
                      <span className="item_text">
                        {translate("Foundation")}{" "}
                        {currentLang.value === "ar" &&
                          "جامعات بريطانيا للابتعاث"}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/undergraduate-in-uk">
                      <span className="item_icon">
                        <i className="fas fa-caret-right"></i>
                      </span>
                      <span className="item_text">
                        {translate("Undergraduate")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/master-in-uk">
                      <span className="item_icon">
                        <i className="fas fa-caret-right"></i>
                      </span>
                      <span className="item_text">{translate("Masters")}</span>{" "}
                        {currentLang.value === "ar" &&
                          "دراسه الماجستير في بريطانيا"}
                    </Link>
                  </li>
                  <li>
                    <Link to="/phd">
                      <span className="item_icon">
                        <i className="fas fa-caret-right"></i>
                      </span>
                      <span className="item_text">{translate("PhD")}</span>{" "}
                        {currentLang.value === "ar" &&
                          "الدكتوراه في بريطانيا"}
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us">
                      <span className="item_icon">
                        <i className="fas fa-caret-right"></i>
                      </span>
                      <span className="item_text">{translate("About")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us">
                      <span className="item_icon">
                        <i className="fas fa-caret-right"></i>
                      </span>
                      <span className="item_text">
                        {translate("Contact Us")}
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-3 col-md-3 col-sm-6">
              <div className="footer_widget">
                <h3 className="footer_widget_title">
                  {translate("Quick Links")}
                </h3>
                <ul className="page_list unordered_list_block">
                  <li>
                    <Link to="/faqs">
                      <span className="item_icon">
                        <i className="fas fa-caret-right"></i>
                      </span>
                      <span className="item_text">{translate("FAQs")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-and-condition">
                      <span className="item_icon">
                        <i className="fas fa-caret-right"></i>
                      </span>
                      <span className="item_text">
                        {translate("Terms & Conditions")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-and-policy">
                      <span className="item_icon">
                        <i className="fas fa-caret-right"></i>
                      </span>
                      <span className="item_text">
                        {translate("Privacy Policy")}
                      </span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/">
                      <span className="item_icon">
                        <i className="fas fa-caret-right"></i>
                      </span>
                      <span className="item_text">Partnerships</span>
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col col-lg-3 col-md-3 col-sm-6">
              <div className="footer_widget">
                <h3 className="footer_widget_title">{translate("Reach Us")}</h3>
                <div className="gmap_canvas">
                  <iframe
                    title="contact"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9610.55698753994!2d-1.1739558!3d52.9729038!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x15f4cc32c196370!2sUKUD+Education+Consultants!5e0!3m2!1sen!2suk!4v1557688188948!5m2!1sen!2suk&z=5"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright_widget">
        <div className="container">
          <p className="copyright_text text-center mb-0">
            {translate("Copyright All Right Reserved 2023,")}
            <b>
              <Link to="/">UKUD</Link>
            </b>
          </p>
        </div>
      </div>
    </footer>
  );
}
