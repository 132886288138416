import React from "react";
import MainLayout from "../main/MainLayout";
import { Link } from "react-router-dom";
import useLocales from "../locales/useLocales";

export default function PrivacyPolicy() {
  const { translate} = useLocales();
  return (
    <MainLayout>
      <section className="page_banner">
        <div className="container">
          <div className="content_wrapper">
            <div className="row align-items-center">
              <div className="col col-lg-7">
                <ul className="breadcrumb_nav unordered_list">
                  <li>
                    <Link to="/">{translate('Home')}</Link>
                  </li>
                  <li>{translate('Privacy Policy')}</li>
                </ul>
                <h1 className="page_title test_mustered">{translate('Privacy Policy Statement')}</h1>

                <p className="page_description text-white">
                 {translate('privacy policy hero section text')}
                </p>
              </div>
              <div className="col col-lg-5">
                <div className="image_widget page_banner_image">
                  <img
                    src="assets/images/course/ukud-foundation-usa.webp"
                    alt="ukud-education-consultancy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="details_section course_details_section section_space_md ">
        <div className="container">
          <h5 className="mb-2">
            1. {translate('Who is responsible for your personal data and how can you contact them?')}
          </h5>

          <p className="mb-5">
            
            {translate('data security')}
            <br />
            <b>{translate('Number')}: +44 (0)1159648211</b>
            <br /> <b>{translate('E-Mail')}: admin@ukud.com</b>
          </p>

          <h5 className="mb-2">2. {translate('Why do we process your personal data?')}</h5>
          <p className="mb-0">
          {translate('We process your personal data in order to be able')}
          </p>
          <ul className="info_list unordered_list_block mb-5">
            <li>
              <i className="fas fa-square"></i>
              <span>
              {translate('to communicate with you regarding your applications or enquiries (name, email, phone number, postal address),')}
              </span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>
              {translate('to create a legal contract (name, email, phone number, postal address)')}
              </span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>{translate('to contact you regarding our special offers (email)')}</span>
            </li>
          </ul>

          <h5 className="mb-2">
            3. {translate('Where do we obtain personal data about you?')}
          </h5>
          <p className="mb-0">{translate('We process personal data that')}</p>
          <ul className="info_list unordered_list_block mb-5">
            <li>
              <i className="fas fa-square"></i>
              <span>
              {translate('you provide to us directly (via email/phone call/verbally/in writing)')}
              </span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>
              {translate('is received from the list of contacts provided by staff in educational events and exhibitions that, you yourself provided to them with consent to share with UKUD Education Consultancy Ltd.')}
              </span>
            </li>
          </ul>
          <h5 className="mb-2">
            4. {translate('To whom do we disclose your personal data?')}
          </h5>
          <p className="mb-5">
           {translate('data disclose')}
          </p>
          <h5 className="mb-2">5. {translate('Where do we store your personal data?')}</h5>
          <p className="mb-5">
            {translate('personal data?')}
          </p>

          <h5 className="mb-2">6. {translate('How long do we keep your personal data?')}</h5>
          <p className="mb-5">
            {translate('How long do we keep your personal data? andwer')}
          </p>

          <h5 className="mb-2">
            7. {translate('What are your rights in relation to personal data?')}
          </h5>
          <p className="mb-0">{translate('You can ask us to:')}</p>
          <ul className="info_list unordered_list_block mb-5">
            <li>
              <i className="fas fa-square"></i>
              <span>{translate('provide you with a copy of your personal data;')}</span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>{translate('correct your personal data;')}</span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>{translate('erase your personal data; or')}</span>
            </li>
            <li>
              <i className="fas fa-square"></i>
              <span>
              {translate('restrict our processing of your personal data.')}
                <br />
                {translate('To exercise these rights or if you have questions about how we process your personal data, please contact us using the contact details in Section 1.')}
              </span>
            </li>
          </ul>

          <h5 className="mb-2">10. {translate('Changes to this Privacy Policy Statement')}</h5>
          <p className="mb-5">
            {translate('Privacy Policy Statement change')}
          </p>
        </div>
      </section>
    </MainLayout>
  );
}
