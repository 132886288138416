import React from "react";
import Accordion from "react-bootstrap/Accordion";
import MainLayout from "../main/MainLayout";
import { Link } from "react-router-dom";
import useLocales from "../locales/useLocales";

const Faqs = (props) => {
  const { translate} = useLocales();
  return (
    <MainLayout>
    
      <section className="page_banner">
          <div className="container">
            <div className="content_wrapper">
              <div className="row align-items-center">
                <div className="col col-lg-7">
                <ul className="breadcrumb_nav unordered_list">
                  <li>
                    <Link to="/">{translate('Home')}</Link>
                  </li>

                  <li>{translate('FAQs')}</li>
                </ul>
                <h1 className="page_title  test_mustered">{translate('FAQs')}</h1>

                <p className="page_description text-white">
                  {translate('faqs hero section text')}
                </p>


                </div>
                <div className="col col-lg-5">
                  <div className="image_widget page_banner_image">
                    <img
                      src="assets/images/faqImage.jpg"
                      alt="ukud-education-consultancy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      <section className="faq_section section_space_md mt-md-5 mt-0">
        <div className="container">
          <div className="section_heading text-center mb-3">
            <div className="row justify-content-center">
              <div className="col col-lg-7">
                <h2 className="heading_text mb-0">
                {translate('Popular Questions to Ask Before Choosing a Course')}
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col col-lg-10">
              <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                  <Accordion.Header>
                  {translate('How many types of Student visas are there?')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="info_list unordered_list_block">
                      <li>
                        <i className="fas fa-square"></i>
                        <span>
                          <Link
                            to="https://www.gov.uk/visa-to-study-english"
                            target="_blank"
                          >
                            {translate('Short-term study visa')}
                          </Link>
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-square"></i>
                        <span>
                          <Link
                            to="https://www.gov.uk/child-study-visa"
                            target="_blank"
                          >
                            {translate('Tier 4 (Child) student visa')}
                          </Link>
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-square"></i>
                        <span>
                          <Link
                            to="https://www.gov.uk/student-visa"
                            target="_blank"
                          >
                            {translate('Tier 4 (General) student visa')}
                          </Link>
                        </span>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                  {translate('How do I apply for a student visa?')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                     {translate('ans How do i apply')}
                      
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    {translate('How do I qualify to come to the United Kingdom to study?')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="info_list unordered_list_block">
                      <li>
                        <i className="fas fa-square"></i>
                        <span>
                          {translate("You can apply for a Tier 4 (General) student visa to study in the UK if you're 16 or over and you")}
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-square"></i>
                        <span>{translate('have been offered a place on a course')}</span>
                      </li>
                      <li>
                        <i className="fas fa-square"></i>
                        <span>
                          {translate('can speak, read, write and understand English')}
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-square"></i>
                        <span>
                          {translate('have enough money to support yourself and pay for your course - the amount will vary depending on your circumstances')}
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-square"></i>
                        <span>
                          {translate("are from a country that's not in the European Economic Area (EEA) or Switzerland")}
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-square"></i>
                        <span>{translate('meet the other eligibility requirements')}</span>
                      </li>
                    </ul>
                    <p className="mt-3">
                    {translate('ans how do qualify')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    {translate('Do I need an IELTS or TOEFL English qualification?')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                    {translate('ans IELTS')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    {translate('What are the academic requirements?')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="info_list unordered_list_block">
                      <li>
                        <i className="fas fa-square"></i>
                        <span>
                         {translate('ans academic req1')}
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-square"></i>
                        <span>{translate('ans academic req2')}</span>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                  {translate('What is the application process?')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                     {translate('ans application process')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                  {translate('What are the UK visa Fees?')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="info_list unordered_list_block">
                      <li>
                        <i className="fas fa-square"></i>
                        <span>
                        {translate('In the UK It costs £328 to apply for this visa from outside the UK.')}
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-square"></i>
                        <span>
                        {translate('In the UK You must pay £328 per person for any dependants.')}
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-square"></i>
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {translate('In the UK You’ll also have to pay the healthcare surchargeas part of your application. Check how much you’ll have to pay before you apply.')}
                        </span>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>{translate('How long you can stay?')}</Accordion.Header>
                  <Accordion.Body>
                    <ul className="info_list unordered_list_block">
                      <li>
                        <i className="fas fa-square"></i>
                        <span>
                          {translate('You can arrive in the UK before your course starts:')}
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-square"></i>
                        <span>
                          {translate('up to 1 week before, if your course lasts 6 months or less')}
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-square"></i>
                        <span>
                          {translate('up to 1 month before, if your course lasts more than 6 months')}
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-square"></i>
                        <span>
                          {translate('How long you can stay depends on the kind of course you’re doing and what study you’ve already completed')}
                        </span>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                  {translate('Is there any Company registration fee?')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="info_list unordered_list_block">
                      <li>
                        <i className="fas fa-square"></i>
                        <span>
                         {translate('ans registration fee1')}
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-square"></i>
                        <span>
                        {translate('ans registration fee2')}
                        </span>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                  {translate('What accommodation is available?')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      {translate('ans of accommodation')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                  {translate('Do I need private medical insurance?')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                    {translate('Medical Insurance is required for anyone entering the UK on a student visa.')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="11">
                  <Accordion.Header>
                  {translate('What is the closing date for applications?')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                     {translate('ans of closing Date')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="12">
                  <Accordion.Header>
                    {translate('Am I allowed to work in the UK while I am studying at a UK college or university?')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      {translate('ans for eligibility')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="13">
                  <Accordion.Header>
                  {translate('Are UK/USA university qualifications recognised internationally?')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      {translate('ans UK/USA')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="14">
                  <Accordion.Header>
                  {translate('What are the starting dates of the courses?')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      {translate('ans starting date of course')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="15">
                  <Accordion.Header>
                  {translate('If there is a placement fee/ deposit for a specific programme, can I claim the deposit back?')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      {translate('and fee/deposit')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="16">
                  <Accordion.Header>
                  {translate('Does UKUD Company operate by professional codes of conduct?')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                     {translate('ans about ukud')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};
export default Faqs;
