import { useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import Slider from "react-slick";
import useLocales from "../locales/useLocales";

export default function Testimonial() {
  const theme = useTheme();
  const { translate } = useLocales();
  // const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isXs = useMediaQuery(theme.breakpoints.down("lg"));
  const [isReadMore, setIsReadMore] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  console.log(isXs, "isXs");
  return (
    <section className="testimonial_section section_space_md pb-5">
      <div className="container">
        <div className="section_heading">
          <div className="row align-items-center">
            <div className="col col-lg-12">
              <h2 className="heading_text mb-0 text-center">
                {translate("Don’t just take our word for it")}
              </h2>
            </div>
          </div>
        </div>
        <div className="testimonial_carousel">
          <div className="common_carousel_2col">
            <Slider {...settings}>
              <div className="carousel_item">
                <div className="testimonial_item">
                  <div className="testimonial_content">
                    <ul className="rating_star unordered_list">
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                    </ul>
                    <h3 className="testimonial_title">Jude Aljizawi</h3>
                    <p>
                      Best agency i dealt with. I didn’t expect them to be VERY
                      fast which is amazing and it didn’t take awhile for them
                      to get my application and all the paperwork ready in such
                      a short notice. Also the staff is amazing and
                      understanding. This wouldn’t be the last time I deal with
                      them at all!
                    </p>

                    <span className="quote_icon">
                      <i className="fas fa-quote-right"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="carousel_item">
                <div className="testimonial_item">
                  <div className="testimonial_content">
                    <ul className="rating_star unordered_list">
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                    </ul>
                    <h3 className="testimonial_title">Aseel R</h3>
                    <p>
                      The customer service was outstanding, and I got what I was
                      looking for easily and professionally. If you ever face
                      difficulties through the process of your universitie
                      application this company is a good option for a fact.
                    </p>

                    {/* <h5 className="testimonial_name">Nahia Colunga</h5> */}
                    <span className="quote_icon">
                      <i className="fas fa-quote-right"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="carousel_item">
                <div className="testimonial_item">
                  <div className="testimonial_content">
                    <ul className="rating_star unordered_list">
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                    </ul>
                    <h3 className="testimonial_title">Raja Alshdaifat</h3>
                    <p>
                      I would like to express my deepest gratitude for the
                      remarkable assistance UKUD have provided me in securing a
                      master's degree offer. Your unwavering dedication,
                      expertise, and tireless efforts have not only facilitated
                      this incredible opportunity
                      {!isReadMore&&
                      <span
                        onClick={() => {
                          setIsReadMore(true);
                        }}
                      >
                        ...<b style={{cursor:'pointer'}}>Read More{'>>'}</b>
                      </span>}
                      {isReadMore && (
                        <>
                          but have also transformed my dreams into reality. I
                          want to acknowledge and appreciate your team's
                          consistent communication, timely updates, and
                          willingness to go the extra mile to address any
                          queries or concerns I had. Your guidance didn't just
                          end with the acceptance letter; you supported me every
                          step of the way. I would like to strongly recommend
                          UKUD which is the best agency that be able to get you
                          an undergraduate or postgraduate offer from any
                          University or Institute in the UK. They deserve 7
                          stars not only 5 stars. Thank you UKUD <span
                        onClick={() => {
                          setIsReadMore(false);
                        }}
                      >
                        <b style={{cursor:'pointer'}}>{'<<'}Read less</b>
                      </span>
                        </>
                      )}
                    </p>

                    <span className="quote_icon">
                      <i className="fas fa-quote-right"></i>
                    </span>
                  </div>
                </div>
              </div>

              <div className="carousel_item">
                <div className="testimonial_item">
                  <div className="testimonial_content">
                    <ul className="rating_star unordered_list">
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                    </ul>
                    <h3 className="testimonial_title">Hussain Alrajab</h3>
                    <p>
                      السلام عليكم، اول شي المكتب جدا مضمون و سريع و تعاملهم
                      جداا ممتاز اي شخص يبي يقدم في بريطانيا انصحه لا يتعداهم
                      انا بنفسي نصحت اصحابي عنهم. فيه مكاتب كثير تقديم و قد
                      تعاملت انا بنفسي مع كثير بس مالقيت نفس تعاملهم و ضمانهم
                      انهم يجبون لك قبول اي احد يبي يقدم عندهم يقدم هو متطمن
                      ١٠٠٪؜
                    </p>

                    <span className="quote_icon">
                      <i className="fas fa-quote-right"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="carousel_item">
                <div className="testimonial_item">
                  <div className="testimonial_content">
                    <ul className="rating_star unordered_list">
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                    </ul>
                    <h3 className="testimonial_title">Rolin Habash </h3>
                    <p>
                      المكتب يتعامل مع كافة المراحل الدراسية ويجيب على كافة
                      التفاصيل اللازمة للدراسة في بريطانيا سجلت لأولادي على
                      مراحل مختلفة فاونديشن وماستر ولغة انكلينزية وكان الرد سريع
                      جدا والمعاملة رائعة أنصح جميع الطلاب بالتقديم عن طريق
                      المكتب
                    </p>

                    <span className="quote_icon">
                      <i className="fas fa-quote-right"></i>
                    </span>
                  </div>
                </div>
              </div>

              <div className="carousel_item">
                <div className="testimonial_item">
                  <div className="testimonial_content">
                    <ul className="rating_star unordered_list">
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                    </ul>
                    <h3 className="testimonial_title">badi alshdifat</h3>
                    <p>
                      تعامل فخم وسهل وراقي وانصح بالتعامل معهم بقوة يوفر الجهد
                      والمال وعندهم صبر بحيث ضل المكتب متابع معي لحد يوم السفر
                      باخر المستجدات رائعيييييين بجد
                    </p>

                    <span className="quote_icon">
                      <i className="fas fa-quote-right"></i>
                    </span>
                  </div>
                </div>
              </div>

              <div className="carousel_item">
                <div className="testimonial_item">
                  <div className="testimonial_content">
                    <ul className="rating_star unordered_list">
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                    </ul>
                    <h3 className="testimonial_title">Amna Altaweel</h3>
                    <p>
                      شركة متميزة وخدمات رائعه فاهمين و متمكنين بالشغل متوفرين
                      على مدار الساعه للاستفسارات حريصين على الشغل و تهمهم راحة
                      العميل ورضاه
                    </p>

                    <span className="quote_icon">
                      <i className="fas fa-quote-right"></i>
                    </span>
                  </div>
                </div>
              </div>

              <div className="carousel_item">
                <div className="testimonial_item">
                  <div className="testimonial_content">
                    <ul className="rating_star unordered_list">
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                    </ul>
                    <h3 className="testimonial_title">Rania Alfadhli</h3>
                    <p>
                      شكراً من الأعماق إلى UKUD , أفضل مكتب على الإطلاق من حيث
                      التعامل و سرعة الاستجابة و بصراحة كل مشكلة و عندهم لها حل
                      ومهما كانت صعوبتها و مخاطرها تلاقيهم كلهم جنود مجندة و في
                      لحظات يأخذون بيديك لتخرج من المأزق بالنسبة لي لولا
                      مساعدتهم ، في آخر رمق كنت سأخسر الإبتعاث الجامعي. فشكراً
                      لا تفي بحقهم و أنصح جميع الطلاب لجميع المراحل التعامل مع
                      مكتب UKUD Education Consultants
                    </p>

                    <span className="quote_icon">
                      <i className="fas fa-quote-right"></i>
                    </span>
                  </div>
                </div>
              </div>

              <div className="carousel_item">
                <div className="testimonial_item">
                  <div className="testimonial_content">
                    <ul className="rating_star unordered_list">
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                    </ul>
                    <h3 className="testimonial_title">Afnan Alaql</h3>
                    <p>
                      من واقع تجربتي تعاملت مع اكثر من مكتب لكن بمجرد ماتعاملت
                      معاهم لاحظت التميز والدقة والجودة في العمل شكرا لتعاملكم
                      الراقي
                    </p>

                    <span className="quote_icon">
                      <i className="fas fa-quote-right"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="carousel_item">
                <div className="testimonial_item">
                  <div className="testimonial_content">
                    <ul className="rating_star unordered_list">
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fas fa-star"></i>
                      </li>
                    </ul>
                    <h3 className="testimonial_title">NYQ - q1n5</h3>
                    <p>
                      مكتب رائع و متميز ساعدوني عشان قبول وعطني نصايح وغيرو وجهة
                      نظري بالجامعات بصورة احسن وقبول باقل من اسبوع وكاسلوب من
                      افضل المكاتب الي كان اسلوبهم زين
                    </p>

                    <span className="quote_icon">
                      <i className="fas fa-quote-right"></i>
                    </span>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}
